import { Form, Row, Spin } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { FormSwitch, getEligibilityStatusInfo, StatusDisplay } from 'ui'

import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import GlobalState from 'trellis:state/globalState'

import {
  EligibilityPatient,
  EligibilityPatients,
  VerifyEligibilityResponse,
} from '../../../../../api/eligibility/eligibility-client'
import {
  GetPatient,
  VerifyPatient,
} from '../../../../../api/eligibility/eligibilityApi'
import { Errors } from '../../../../../constants/errors'
import { useGlobalContext } from '../../../../../context/GlobalContextProvider'
import {
  extractTypeProperties,
  showMessage,
} from '../../../../../utilities/general'
import { useEligibilityContext } from '../../../shared/context/EligibilityContext'
import { EligibilityResponseProps } from '../../../shared/EligibilityResponse'
import InsuranceControls from '../../../shared/form/controls/InsuranceControls'
import PatientControls from '../../../shared/form/controls/PatientControls'
import ProviderControls from '../../../shared/form/controls/ProviderControls'
import {
  toggleSubscriber,
  transformPatient,
} from '../../../shared/form/utilities/eligibilityFormDataHandling'
import { PatientEligibilityFormFields } from '../../../shared/form/utilities/eligibilityFormTyping'
import { ParticipatingCarrier } from '../../../shared/utilities/eligibilityTyping'
import { getEligibilityResponse } from '../../../shared/utilities/getEligibilityResponse'

interface OneTimeRequestEligibilityFormProps {
  hasPatient: boolean
  patient: EligibilityPatient
  patientStatus: { status: string; toolTip: string }
  setHasPatient: Dispatch<SetStateAction<boolean>>
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>
  setPatientStatus: Dispatch<
    SetStateAction<{ status: string; toolTip: string }>
  >
  setVerificationResponse: Dispatch<SetStateAction<EligibilityResponseProps>>
  setVerifying: Dispatch<SetStateAction<boolean>>
}

const OneTimeRequestEligibilityForm: FC<OneTimeRequestEligibilityFormProps> = ({
  hasPatient,
  patient,
  patientStatus,
  setHasPatient,
  setPatient,
  setPatientStatus,
  setVerificationResponse,
  setVerifying,
}) => {
  const { authentication, practiceDetails } = useGlobalContext()
  const { providers, participatingCarriers } = useEligibilityContext()

  const [displayName, setDisplayName] = useState<string>(
    patient.PatientLastName && patient.PatientFirstName
      ? `${patient.PatientLastName}, ${patient.PatientFirstName}`
      : 'New Patient',
  )
  const [loading, setLoading] = useState<boolean>(true)

  const [oneTimeRequestEligibilityForm] = Form.useForm()

  useEffect(() => {
    if (!hasPatient && patient?.PatientId !== '0') getPatient(patient.PatientId)
    else {
      oneTimeRequestEligibilityForm.setFieldsValue({
        ...patient,
        ProviderFirstName: practiceDetails?.providerFirstName || '',
        ProviderLastName: practiceDetails?.providerLastName || '',
        IndividualNpi: practiceDetails?.providerNPI || '',
      })
      setLoading(false)
    }
  }, [practiceDetails])

  const getPatient = async (patientId: string) => {
    await GetPatient(patientId)
      .then(({ data }) => {
        const patientCopy = transformPatient(
          patient,
          data,
          participatingCarriers,
          providers,
          practiceDetails,
        )

        oneTimeRequestEligibilityForm.setFieldsValue({ ...patientCopy })

        if (patientCopy.PatientId !== '0')
          setDisplayName(
            `${patientCopy.PatientLastName}, ${patientCopy.PatientFirstName}`,
          )

        setPatientStatus(
          getEligibilityStatusInfo(
            data.Status,
            GlobalState.EligibilityStatusMapping.peek(),
          ),
        )
        setPatient({ ...patientCopy })
        setHasPatient(true)
      })
      .catch(() => showMessage(Errors.patientInfoError))
      .finally(() => setLoading(false))
  }

  const handleVerifyPatient = () => {
    setVerifying(true)

    const patientCopy = { ...patient }

    patientCopy.PatientId = '0'

    if (patient.PatientIsSub) {
      patientCopy.SubscriberFirstName = patientCopy.PatientFirstName
      patientCopy.SubscriberMiddleName = patientCopy.PatientMiddleName
      patientCopy.SubscriberLastName = patientCopy.PatientLastName
      patientCopy.SubscriberSuffix = patientCopy.PatientSuffix
      patientCopy.SubscriberBirthdate = patientCopy.PatientBirthdate
      patientCopy.SubscriberGender = patientCopy.PatientGender
    }

    oneTimeRequestEligibilityForm
      .validateFields()
      .then(() => verifyPatient(patientCopy))
      .catch(() => {
        showMessage(Errors.formValidationErrors)
        setVerifying(false)
      })
  }

  const verifyPatient = async (patientValues: EligibilityPatient) => {
    //Validate carrierID
    if (!patientValues.CarrierId || patientValues.CarrierId === '0') {
      const carrierFound = participatingCarriers.filter(
        (carrier: ParticipatingCarrier) =>
          carrier.CarrierName?.toLowerCase() ==
          patientValues.CarrierName?.toLowerCase(),
      )
      patientValues.CarrierId = carrierFound[0]?.CarrierId
    }

    await VerifyPatient(patientValues.PatientId, patientValues)
      .then(({ data }) => handleEligibilityResponse(patientValues, data))
      .catch(() => showMessage(Errors.verifyPatientError, 'error'))
      .finally(() => setVerifying(false))
  }

  const handleEligibilityResponse = async (
    patientValues: EligibilityPatient,
    verifyResponse: VerifyEligibilityResponse,
  ) => {
    await getEligibilityResponse<EligibilityPatients>(
      authentication,
      { ...patientValues, Status: verifyResponse.Status },
      verifyResponse.HtmlResult,
      verifyResponse.EligibilityId,
    ).then((response) => setVerificationResponse(response))
  }

  const handleValuesChange = (allValues: PatientEligibilityFormFields) => {
    const patientCopy = extractTypeProperties<
      EligibilityPatient,
      PatientEligibilityFormFields
    >({ ...patient }, { ...allValues })
    setPatient({ ...patientCopy })
    oneTimeRequestEligibilityForm.setFieldsValue({ ...patient, ...allValues })
  }

  return (
    <Spin spinning={loading}>
      <Form
        colon={false}
        form={oneTimeRequestEligibilityForm}
        name='oneTimeRequestEligibilityForm'
        onFinish={handleVerifyPatient}
        onValuesChange={(_, allValues) => handleValuesChange(allValues)}
        requiredMark={false}
      >
        <Row
          align='middle'
          className='mb-100'
        >
          <h1 className='page-content-header__title'>{displayName}</h1>
          {patientStatus?.status && (
            <div className='status-badge-container'>
              <StatusDisplay
                status={patientStatus?.status}
                toolTip={patientStatus?.toolTip}
                type='badge'
                getStatusOverride={getStatusOverride}
              />
            </div>
          )}
        </Row>
        <PatientControls
          isModal={true}
          patient={patient}
        />
        <Row
          align='middle'
          className='mb-200'
        >
          <p>Patient is a Subscriber</p>
          <FormSwitch
            checked={patient.PatientIsSub}
            className='patient-is-subscriber ml-100'
            dataTestId='pat-sub-one-time-request-switch'
            name='PatientIsSub'
            onChangeHandler={() =>
              toggleSubscriber(
                patient,
                setPatient,
                oneTimeRequestEligibilityForm,
              )
            }
          />
        </Row>
        <section>
          <h3 className='page-section__title'>Subscriber</h3>
          <PatientControls
            isModal={true}
            patient={patient}
            prefix='Subscriber'
          />
        </section>
        <section>
          <h3 className='page-section__title'>Insurance</h3>
          <InsuranceControls
            isModal={true}
            patient={patient}
            setPatient={setPatient}
          />
        </section>
        <section>
          <h3 className='page-section__title'>Provider</h3>
          <ProviderControls
            isModal={true}
            patient={patient}
            setPatient={setPatient}
          />
        </section>
      </Form>
    </Spin>
  )
}

export default OneTimeRequestEligibilityForm
