import React, { createContext, FC, useEffect, useState } from 'react'

import { IAuthenticatedApiModel } from '../../utilities/api'
import { useClaimDetailContext } from '../claims/context/claimDetailContext'
import { useClaimGridContext } from '../claims/context/claimGridContext'
import { CarrierControls } from '../claims/detail/tabs/patient/carrierControls'
import { IAttachment, IAttachmentImage } from './attachment.interface'
import AttachmentDetails from './attachmentDetails/attachmentDetails'
import AttachmentImageDetails from './imageDetails/attachmentImageDetails'

import './attachment.scss'

import { overMaxSize } from './attachmentFunctions'

export const SavedAttachmentContext = createContext<boolean>(false)
const Authentication = createContext<IAuthenticatedApiModel>(null)

type AttachmentProps = {
  attachment?: IAttachment
  setAttachment: (attachment: IAttachment) => void
  showList: () => void
}

const Attachment: FC<AttachmentProps> = ({
  attachment,
  setAttachment,
  showList,
}) => {
  const { authentication } = useClaimGridContext()
  const {
    attachmentRequirements,
    claim,
    supportsAttachment,
    setIsCreatedFromSentAttachment,
    sentAttachments,
    refImageTypes,
  } = useClaimDetailContext()
  const { validateRequirements } = useClaimDetailContext()
  const [showImageDetailsView, setShowImageDetailsView] =
    useState<boolean>(false)
  const [attachmentImage, setAttachmentImage] = useState<IAttachmentImage>()
  //.net max int for server side DELETE calls
  const maxInt = 2147483647

  useEffect(() => {
    if (supportsAttachment) {
      validateRequirements()
    }

    if (
      supportsAttachment != null &&
      supportsAttachment == false &&
      (sentAttachments !== null || sentAttachments?.length > 0)
    ) {
      setIsCreatedFromSentAttachment(false)
    }
  }, [])

  const getRandomInteger = (negative: boolean): number => {
    let val = Math.floor(Math.random() * maxInt) + 1

    if (negative) {
      val = -1 * val
    }

    return val
  }

  const handleAttachment = (attachment: IAttachment) => {
    setAttachment(attachment)
    validateRequirements()
  }

  const handleAttachmentImageDetailsView = (
    attachmentImage: IAttachmentImage,
    showDetailsView: boolean,
  ) => {
    setAttachmentImage(attachmentImage)
    setShowImageDetailsView(showDetailsView)
  }

  const handleAttachmentImages = (
    attachmentImages: IAttachmentImage[],
  ): IAttachmentImage[] => {
    attachment.attachmentImages = attachment.attachmentImages ?? []
    attachmentImages.forEach(function (image, index) {
      image.imageId = getRandomInteger(true) + index
      attachment.attachmentImages.push(image)
    })

    if (attachmentImages?.length > 0) {
      handleAttachment(attachment)
    }

    return attachment.attachmentImages
  }

  const storeAttachmentImageLocal = (
    attachmentImage: IAttachmentImage,
    openDetailView: boolean,
  ) => {
    // Handle special considerations for new images on first addition
    if (attachmentImage?.isNewImage && !attachmentImage.imageId) {
      // Initialize attachment images array if not null
      attachment.attachmentImages = attachment.attachmentImages ?? []

      attachmentImage.imageId = getRandomInteger(true)
      attachment.attachmentImages.push(attachmentImage)
    }

    if (attachmentImage) {
      handleAttachment(attachment)
    }

    handleAttachmentImageDetailsView(attachmentImage, openDetailView)
  }

  return (
    <SavedAttachmentContext.Provider value={attachment?.isSentAttachment}>
      <Authentication.Provider value={authentication}>
        <div className='pt-100 pb-100 pl-025'>
          <div id='attachment-container'>
            {supportsAttachment != null && supportsAttachment == false && (
              <>
                <div className='page-section__title'>
                  Please Select a Carrier
                </div>
                <div className='section'>
                  To submit an attachment with this claim, select the carrier
                  from the drop-down below. If the carrier is not listed, then
                  no electronic connection is available for attachments.
                </div>
                <CarrierControls displayAttachmentCarrier={true} />
              </>
            )}
            {supportsAttachment && showImageDetailsView && (
              <AttachmentImageDetails
                attachmentImage={attachmentImage}
                storeAttachmentImageLocal={storeAttachmentImageLocal}
                attachmentImageTypes={refImageTypes}
                overMaxSize={overMaxSize(attachment, attachmentImage)}
                showImageDetailsView={showImageDetailsView}
                claim={claim}
              />
            )}
            {supportsAttachment && !showImageDetailsView && (
              <AttachmentDetails
                attachment={attachment}
                attachmentRequirements={attachmentRequirements}
                handleAttachment={handleAttachment}
                storeAttachmentImageLocal={storeAttachmentImageLocal}
                handleAttachmentImageDetailsView={
                  handleAttachmentImageDetailsView
                }
                handleAttachmentImages={handleAttachmentImages}
                showList={showList}
              />
            )}
          </div>
        </div>
      </Authentication.Provider>
    </SavedAttachmentContext.Provider>
  )
}

export default Attachment
