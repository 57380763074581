import { observer } from '@legendapp/state/react'
import { Form, Spin } from 'antd'
import { InternalNamePath } from 'antd/lib/form/interface'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { FormError, handleValuesChange } from 'utils'

import { PracticeInfo } from 'trellis:api/practice/practice-client'
import { Errors } from 'trellis:constants/errors'
import { NotifyText } from 'trellis:constants/notifyText'
import GlobalState from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'

import BillingAddress from './controls/BillingAddress'
import ContactInfo from './controls/ContactInfo'
import PracticeManagementSystem from './controls/PracticeManagementSystem'
import ProviderList from './ProviderList/ProviderList'

type PracticeInfoProps = {
  formErrors: FormError[]
  isSaving: Dispatch<SetStateAction<boolean>>
  resetForm: boolean
  setFormErrors: Dispatch<SetStateAction<FormError[]>>
}

type PracticeInfoFields = {
  contactName: string
  customerAddress1: string
  customerAddress2: string
  customerCity: string
  customerEmail: string
  customerPhone: string
  customerState: string
  customerZip: string
  officeName: string
  p_Software: string
}

const emptyPracticeInfoFields: PracticeInfoFields = {
  contactName: '',
  customerAddress1: '',
  customerAddress2: '',
  customerCity: '',
  customerEmail: '',
  customerPhone: '',
  customerState: '',
  customerZip: '',
  officeName: '',
  p_Software: '',
}

const PracticeInfoForm: FC<PracticeInfoProps> = observer(
  ({ formErrors, isSaving, resetForm, setFormErrors }) => {
    const practiceDetails = GlobalState.PracticeInfo.get()

    const [loading, setLoading] = useState<boolean>(false)
    const [practiceInfo, setPracticeInfo] = useState<PracticeInfo>(
      practiceDetails ? { ...practiceDetails } : emptyPracticeInfoFields,
    )

    useEffect(() => {
      if (resetForm) {
        setPracticeInfo(emptyPracticeInfoFields)
        practiceInfoForm.setFieldsValue(emptyPracticeInfoFields)
      }
    }, [resetForm])

    const [practiceInfoForm] = Form.useForm()
    practiceInfoForm.setFieldsValue({ ...practiceInfo })

    const savePracticeInfo = async () => {
      isSaving(true)
      const updatedPracticeInfo = {
        ...practiceInfo,
        partnerClaimsSupported: getPartnerClaimSupport(),
      }
      setPracticeInfo(updatedPracticeInfo)
      await api
        .savePracticeDetails(practiceInfo)
        .then(() => {
          GlobalState.PracticeInfo.set(updatedPracticeInfo)
          showMessage(NotifyText.practiceInfoUpdateSuccess, 'success')
        })
        .catch(() => showMessage(NotifyText.updatePracticeInfoError, 'error'))
        .finally(() => isSaving(false))
    }

    const getPartnerClaimSupport = () => {
      const pmsMatch = GlobalState.Constants.supportedPMS
        .get()
        ?.find((c) => c.pmsName === practiceInfo.p_Software)

      return pmsMatch.partnerClaimsSupported
    }
    const onValuesChange = (
      changedValues: { [key in keyof PracticeInfo]: string },
      allValues: PracticeInfo,
    ) => {
      handleValuesChange<PracticeInfo>(
        allValues,
        changedValues,
        formErrors,
        practiceInfoForm,
        setFormErrors,
        setPracticeInfo,
        practiceInfo,
      )
    }

    const onFinish = async () => await savePracticeInfo()

    const onFinishFailed = (
      errorFields: { name: InternalNamePath; errors: string[] }[],
    ) => {
      showMessage(Errors.formValidationErrors)
      setFormErrors(errorFields)
    }

    return (
      <>
        {loading ? (
          <Spin spinning={loading} />
        ) : (
          <>
            <Form
              colon={false}
              form={practiceInfoForm}
              name='practiceInfoForm'
              onFinish={onFinish}
              onFinishFailed={(info) => onFinishFailed(info.errorFields)}
              onValuesChange={(changedValues, allValues) =>
                onValuesChange(changedValues, allValues)
              }
              requiredMark={false}
            >
              <div className='form-section-row form-section-row--default'>
                <section className='page-section'>
                  <h2 className='page-section__title'>Contact Info</h2>
                  <ContactInfo />
                </section>
                <section className='page-section'>
                  <h2 className='page-section__title'>Billing Address</h2>
                  <BillingAddress />
                </section>
              </div>
              <div className='form-section-row form-section-row--default'>
                <section className='page-section'>
                  <h2 className='page-section__title'>
                    Practice Management System
                  </h2>
                  <PracticeManagementSystem />
                </section>
              </div>
            </Form>
            <ProviderList />
          </>
        )}
      </>
    )
  },
)

export default PracticeInfoForm
