import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { observer, Show, useObservable } from '@legendapp/state/react'
import { Modal } from 'antd'
import React, { useEffect } from 'react'

import { SummaryCardsContainer, SummaryCardsContainerItem } from 'ui'
import { LogError } from 'utils'

import GlobalState, { LDFlags$ } from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'
import { useSocketContext } from 'trellis:utilities/webSocketClient'

import {
  ClaimActionContextProvider,
  useClaimActionContext,
} from './context/claimActionContext'
import {
  ClaimControlContextProvider,
  useClaimControlContext,
} from './context/claimControlContext'
import {
  ClaimGridContextProvider,
  useClaimGridContext,
} from './context/claimGridContext'
import { ClaimDetail } from './detail/claimDetail'
import { ClaimGrid } from './grid/claimGrid'
import {
  getClaimSummaryCardItems,
  handleActiveSummaryCard,
} from './util/claim-status-summary-helpers'

import './claims.scss'

import { VyneButton } from '@vynedental/design-system'

import { ClaimArchiveContextProvider } from 'trellis:components/claims/controls/ClaimArchiveSearch/context/ClaimArchiveContext'

import { ClaimGridState } from './claimTypes'
import { SendAllNotification } from './controls/notification/sendAllNotification'
import { SendAllNotificationData } from './util/claimTypes'

const ClaimManagement = observer(() => {
  const flags = LDFlags$.get()
  const { socket } = useSocketContext()
  const {
    authenticationLoaded,
    ClaimState$,
    setClaimId,
    queuedStatusSummary$,
    setCarriers,
    setDetailsTab,
    setState,
    state,
  } = useClaimGridContext()
  const { sendingAll, handleSend, sendComplete, sendAllMsgData } =
    useClaimActionContext()
  const { setPulse } = useClaimControlContext()

  const getClaimsSummaryPreference = () => {
    const claimsSummaryPreferenceStoredVal = localStorage.getItem('claims-summary-preference')
    const claimsSummaryPreferenceInitialVal = claimsSummaryPreferenceStoredVal !== null ? JSON.parse(claimsSummaryPreferenceStoredVal) : true
    return claimsSummaryPreferenceInitialVal
  }

  // For Summary Cards \\
  const queuedStatusSummary = queuedStatusSummary$.get()
  const state$ = useObservable<ClaimGridState>(state)
  const showSummaryCards = useObservable<boolean>(getClaimsSummaryPreference())
  const summaryCardItems = useObservable<SummaryCardsContainerItem[]>()
  const activeSummaryCard = useObservable<string | number>('default')

  // Break point to hide the summary cards below 1024px
  const handleSummaryCardVisibility = (event: MediaQueryListEvent) => {
    if (event.matches) showSummaryCards.set(getClaimsSummaryPreference())
    else showSummaryCards.set(false)
  }



  useEffect(() => {
    const query = matchMedia('(min-width: 1024px)')
    query.addEventListener('change', handleSummaryCardVisibility)

    state$.onChange((newState) => {
      setState(newState.value)
    })

    return () =>
      query.removeEventListener('change', handleSummaryCardVisibility)
  })

  useEffect(() => {
    state$.set(state)
  }, [state.Key])

  useEffect(() => {
    handleActiveSummaryCard(state, activeSummaryCard)
  }, [state?.Filters.Config.Status])

  useEffect(() => {
    if (queuedStatusSummary?.summary) {
      handleStatusSummary()
    }
  }, [queuedStatusSummary])

  const handleStatusSummary = async () => {
    summaryCardItems.set(
      await getClaimSummaryCardItems(
        queuedStatusSummary,
        activeSummaryCard,
        state$,
      ),
    )
  }
  // End Summary Cards \\

  const { confirm } = Modal

  let claimCount = 0
  let showNewClaims = false

  // NOTE: we would like to use UrlSearchParams but it is not supported by IE
  // When we move to SPA and have react router we could implement the useSearchParams hook (if we've decided to not support IE by that point since it makes use of Object.fromEntries which is not supported in IE)
  type UrlParams = {
    [key: string]: string
  }
  const urlParams: UrlParams = {}
  decodeURIComponent(location.href).replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (_, key, value) => (urlParams[key] = value),
  )

  useEffect(() => {
    if (authenticationLoaded && urlParams.claimId) {
      const stateCopy = { ...state }
      stateCopy.ActiveTab = 'Sent'

      setState(stateCopy)
      setClaimId(urlParams.claimId)
      setDetailsTab('Attachments')
    }
  })

  useEffect(() => {
    try {
      socket?.current.addEventListener('message', onMessage)

      return () => socket.current.removeEventListener('message', onMessage)
    } catch (ex) {
      LogError(ex, 'Error Configuring Websocket')
    }
  }, [socket?.current])

  const onMessage = async (event: MessageEvent) => {
    const message = event.data
    //leave this console log to view websocket messages in the console
    console.debug(message)
    if (message === 'new claim') {
      incomingNewClaimMessage()
    } else {
      let messageJson = null
      try {
        messageJson = JSON.parse(message)
      } catch (e) {
        LogError(e, 'Error parsing message')
        return
      }

      if (messageJson?.MessageType === 'ProcessAllClaimsResponse') {
        await incomingProcessAllClaimsResponse(messageJson)
      }
    }
  }

  const incomingProcessAllClaimsResponse = async (
    messageJson: SendAllNotificationData,
  ) => {
    sendAllMsgData.set(messageJson)
    if (
      messageJson?.IsComplete ||
      messageJson?.Status.includes('Claim processing completed')
    ) {
      const timer = setTimeout(() => {
        sendAllMsgData.Status.set(null)
        sendComplete()
      }, 5000) //  = 5 seconds
      // Clean up the timer on component unmount
      return () => {
        clearTimeout(timer)
      }
    }
  }
  const incomingNewClaimMessage = () => {
    claimCount++
    if (!showNewClaims) {
      showNewClaims = true
      setTimeout(newClaimAlert, 30000)
    }
  }

  const newClaimAlert = () => {
    setPulse(true)
    showMessage(
      `${claimCount} new claim${
        claimCount == 1 ? '' : 's'
      } available. Update to view.`,
      'info',
      10,
    )
    showNewClaims = false
    claimCount = 0
    setTimeout(() => {
      setPulse(false)
    }, 10000)
  }

  const confirmSendAll = () => {
    const modal = confirm({ 
      title: 'Send All',
      icon: <ExclamationCircleTwoTone twoToneColor='#91C73D' />,
      content: 'This action will send all Unsent claims with a status of Ready.',
      footer: () => (
        <div>
          <VyneButton
            dataTestId='cancel-send-all-claims-button'
            key='cancel'
            onClick={() => {
              showMessage('0 claims were submitted.')
              modal.destroy()
            }}
          >
            Cancel
          </VyneButton>
          <VyneButton
            dataTestId='confirm-send-all-claims-button'
            key='submit'
            type='primary'
            onClick={() => {
              handleSend(true)
              modal.destroy()
            }}
          >
            Confirm
          </VyneButton>
        </div>
      ),
    })
  }

  useEffect(() => {
    api.getCarriers().then(({ data }) => {
      setCarriers(data.Carriers)
    })
  }, [])

  const handleCreateClaim = () => {
    ClaimState$.showClaimDetail.set(true)
    ClaimState$.isNewClaim.set(true)
  }

  const summaryClick = () => {
    const newShowSummaryValue = !showSummaryCards.get()
    showSummaryCards.set(newShowSummaryValue)
    localStorage.setItem(
      'claims-summary-preference',
      JSON.stringify(newShowSummaryValue),
    )
  }

  return (
    <section className='page-content__container page-content__container--with-page-header'>
      {sendingAll && <SendAllNotification data={sendAllMsgData.get()} />}
      {ClaimState$.showClaimDetail.get() && <ClaimDetail />}
      {!urlParams.claimId && !ClaimState$.showClaimDetail.get() && (
        <>
          <section className='grid'>
            <div className='flex justify-between items-center'>
              <div className='flex items-center gap-100'>
                <h1 className='page-content-header__title'>Claim Management</h1>
                {flags.claimValidation && state.ActiveTab === 'Unsent' && (
                  <p
                    data-testid='summaryCardShowHideLink'
                    className='text-link'
                    onClick={summaryClick}
                  >
                    {showSummaryCards.get() ? 'Hide' : 'Show'} Summary
                  </p>
                )}
              </div>
              <div className='flex items-center gap-100'>
                {state.ActiveTab === 'Unsent' && (
                  <>
                    <Show
                      if={
                        LDFlags$.createAClaim &&
                        GlobalState.ClaimSettings.ManualClaimEntry
                      }
                    >
                      {() => (
                        <VyneButton
                          dataTestId='create-manual-claim-button'
                          onClick={handleCreateClaim}
                          size='large'
                        >
                          Create Manual Claim
                        </VyneButton>
                      )}
                    </Show>
                    <VyneButton
                      dataTestId='send-all-claims-button'
                      disabled={state.SelectAll}
                      loading={sendingAll}
                      onClick={() => confirmSendAll()}
                      size='large'
                      type='primary'
                    >
                      Send All
                    </VyneButton>
                  </>
                )}
              </div>
            </div>
            <SummaryCardsContainer
              activeCard={activeSummaryCard}
              items={summaryCardItems.get()}
              testId='claimSummaryCardContainer'
              isVisible={
                flags.claimValidation &&
                state.ActiveTab === 'Unsent' &&
                showSummaryCards.get()
              }
            />
          </section>
          <div>
            <ClaimGrid />
          </div>
        </>
      )}
    </section>
  )
})

const Claims = () => {
  return (
    <ClaimGridContextProvider>
      <ClaimControlContextProvider>
        <ClaimActionContextProvider>
          <ClaimArchiveContextProvider>
            <ClaimManagement />
          </ClaimArchiveContextProvider>
        </ClaimActionContextProvider>
      </ClaimControlContextProvider>
    </ClaimGridContextProvider>
  )
}

export default Claims
