import { batch } from '@legendapp/state'
import { VyneUserTypes } from '@vynedental/design-system'
import { Mutex } from 'async-mutex'
import { AxiosError } from 'axios'
import { LDClient } from 'launchdarkly-js-client-sdk'
import { Link, Location, NavigateFunction } from 'react-router-dom'

import { ClearSentryUser, ErrorCodes, LogError, SetSentryUser } from 'utils'

import { GetUser } from 'trellis:api/account/accountApi'
import {
  ProblemDetails,
  TokenResponse,
} from 'trellis:api/authentication/authentication-client'
import {
  Logout,
  TokenLogin,
} from 'trellis:api/authentication/authenticationApi'
import { EligbilityStatusMapping } from 'trellis:api/eligibility/eligibility-client'
import { GetStatusMapping } from 'trellis:api/eligibility/eligibilityApi'
import {
  GetBillingDetails,
  GetConstants,
  GetPmsDataStatus,
  GetPracticeInfo,
} from 'trellis:api/practice/practiceApi'
import { StatementsGetFacilityResponse } from 'trellis:api/statements/statements-client'
import { GetPaymentsStatus } from 'trellis:api/statements/statementsApi'
import { Errors } from 'trellis:constants/errors'
import { GlobalState, logoutError, TrellisJWT } from 'trellis:state/globalState'
import { parseActiveServices } from 'trellis:utilities/activeServiceHelper'
import api, { IAuthenticatedApiModel } from 'trellis:utilities/api'
import { decodeJWT, getSearchParamsLowerCase } from 'trellis:utilities/general'
import { clearLdContext, updateLdContext } from 'trellis:utilities/ldHelper'
import { destroyStorage } from 'trellis:utilities/localStorageHelpers'
import {
  clearPendo,
  identifyPendoAuthedUser,
} from 'trellis:utilities/pendoHelper'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'

const getAuthModel = (
  loginResponse: TokenResponse,
  jwt: TrellisJWT,
): IAuthenticatedApiModel => {
  return {
    AccessToken: loginResponse.accessToken,
    AuthToken: loginResponse.authToken,
    CustomerId: loginResponse.customerId,
    CustomerIdNumber: loginResponse.customerId,
    CustomerTypeId: loginResponse.customerTypeId,
    PmgId: loginResponse.pmgId,
    PmgRegionId: loginResponse.pmgRegionId,
    RegistrationToken: loginResponse.registrationToken,
    ActiveServices: jwt.active_services?.split(',') ?? [],
  }
}

export const processLoginResponse = async (
  loginResponse: TokenResponse,
  ldClient: LDClient,
) => {
  const currentCustomerID = GlobalState.Auth.CustomerId.peek()
  if (currentCustomerID && currentCustomerID !== loginResponse.customerId) {
    Logout(
      GlobalState.Auth.AccessToken.peek(),
      GlobalState.Auth.RegistrationToken.peek(),
      GlobalState.Auth.AuthToken.peek(),
    ).catch(() => {})
  }

  SetSentryUser(null, null, loginResponse.customerId) //set gcid in case there's an issue decoding the jwt

  const jwt = decodeJWT(loginResponse.accessToken)

  const customerAuth = getAuthModel(loginResponse, jwt)
  const activeServices = parseActiveServices(customerAuth.ActiveServices)

  const userType = activeServices?.DENTAL_INTEL
    ? VyneUserTypes.dentalIntelligence
    : activeServices?.REMOTE_LITE
      ? VyneUserTypes.remoteLite
      : VyneUserTypes.trellis

  SetSentryUser(
    jwt.email,
    loginResponse.customerId,
    jwt.user_id,
    customerAuth.ActiveServices,
  ) //set the full user identity

  /*TODO: check if the user is already authed and compare the user/customer id to what's in state
    -wipe state if they don't match so data doesn't carry over
    -either skip the other api calls or do a fire and forget on them so there isn't a huge delay coming from opera
  */

  GlobalState.set({
    AuthLoading: true,
    IsAuthenticated: false,
    Auth: customerAuth,
    ActiveServices: activeServices,
    DecodedJwt: jwt,
    HasPmsData: false,
  })

  const userData = await Promise.all([
    getUserInfo(jwt),
    getPracticeDetails(),
    getLegalBusinessStatus(),
    getPmsDataStatus(),
    getBillingDetails(),
    getConstants(),
  ])

  const userInfo = userData[0]
  const practiceinfo = userData[1]
  const legalBusinessStatus = userData[2]
  const hasPmsData = userData[3]
  const billingDetails = userData[4]
  const constants = userData[5]

  let paymentsStatus: StatementsGetFacilityResponse
  try {
    paymentsStatus = await getPaymentsStatus()
  } catch (e) {
    LogError(e, 'Failed to retrieve payments status')
  }

  let eligibilityStatusMappings: EligbilityStatusMapping[]
  try {
    eligibilityStatusMappings = await getEligibilityStatusMappings()
  } catch (e) {
    LogError(e, 'Failed to retrieve Eligibility Status Mappings')
  }

  const ldCall = updateLdContext(
    ldClient,
    hasPmsData,
    jwt.active_services,
    userInfo.globalCustomerID,
  )
  identifyPendoAuthedUser(
    userInfo,
    practiceinfo,
    jwt.active_services,
    legalBusinessStatus,
  )
  await ldCall

  GlobalState.set({
    AuthLoading: false,
    IsAuthenticated: true,
    Auth: customerAuth,
    ActiveServices: activeServices,
    DecodedJwt: jwt,
    ActiveServicesString: jwt.active_services,
    PracticeInfo: practiceinfo,
    UserInfo: userInfo,
    LegalBusinessStatus: legalBusinessStatus,
    HasPmsData: hasPmsData,
    BillingDetails: billingDetails,
    Constants: constants,
    PaymentsStatus: paymentsStatus,
    EligibilityStatusMapping: eligibilityStatusMappings,
    UserType: userType,
  })
}

const isTokenExpired = (token: TrellisJWT) => {
  if (!token?.exp) {
    return true
  }

  const expirationTime = token.exp //  expiration time claim
  const currentTime = Math.floor(Date.now() / 1000) // Convert current time to seconds

  return expirationTime <= currentTime
}

export const isTrellisAuthExpired = () => {
  return isTokenExpired(GlobalState.DecodedJwt.peek())
}

export const logoutUserExpiredSession = () => {
  logoutError.set(Errors.sessionExpired)
  GlobalState.IsAuthenticated.set(false)
}

const refreshMutex = new Mutex()
/**
Attempts to refresh the jwt, on failure it sets IsAuthenticated to false to trigger logout logic
*/
export const refreshTrellisAuth = async (
  originalAuth: IAuthenticatedApiModel,
): Promise<IAuthenticatedApiModel> => {
  //mutex to prevent multiple calls from doing this at once
  return await refreshMutex
    .runExclusive(async (): Promise<IAuthenticatedApiModel> => {
      if (!GlobalState.IsAuthenticated.peek()) {
        //skip if they're logging out
        return Promise.reject(new Error(ErrorCodes.auth_refresh_failed))
      } else if (
        GlobalState.Auth.AccessToken.peek() != originalAuth?.AccessToken
      ) {
        //skip if another call got here first
        return GlobalState.Auth.peek()
      }

      const response = await TokenLogin().catch(() => {
        //If they failed to refresh the token kill the session and send them to login
        logoutUserExpiredSession()
      })

      if (!response) {
        return Promise.reject(new Error(ErrorCodes.auth_refresh_failed))
      }

      const jwt = decodeJWT(response.data.accessToken)
      const customerAuth = getAuthModel(response.data, jwt)

      GlobalState.set({
        ...GlobalState.peek(),
        Auth: customerAuth,
        DecodedJwt: jwt,
      })

      return customerAuth
    })
    .catch((e) => Promise.reject(e))
}

const getUserInfo = async (jwt: TrellisJWT) => {
  const userInfo = await GetUser(jwt?.user_id)
  return userInfo.data
}

const getPracticeDetails = async () => {
  const response = await GetPracticeInfo()
  return response.data.trellisPracticeInfo
}

const getConstants = async () => {
  const response = await GetConstants()
  return response.data
}

const getLegalBusinessStatus = async () => {
  const response = await api.getLegalBusinessStatus()
  return response.data.data
}

const getPmsDataStatus = async () => {
  const response = await GetPmsDataStatus()
  return response.data.hasPMSData
}

const getBillingDetails = async () => {
  const response = await GetBillingDetails()
  return response.data
}

const getPaymentsStatus = async () => {
  const response = await GetPaymentsStatus()
  return response.data
}

const getEligibilityStatusMappings = async () => {
  const response = await GetStatusMapping()
  return response.data
}

export const getLoginRedirectURL = (searchParams: URLSearchParams) => {
  let redirectURL: string = ''

  const returnUrl = getSearchParamsLowerCase(searchParams).get('returnurl')
  const queryString = getPostLoginQueryString(searchParams)

  // Don't append the current URL if we're coming from the Eula or Payment Info pages,
  // which are post auth but sit outside of the normal post auth layout
  if (returnUrl && returnUrl !== '/account/loginpaymentInfo') {
    redirectURL = `${returnUrl}${queryString}`
  } else {
    redirectURL = RoleHelper$.defaultRoute.peek()
  }

  return redirectURL
}

export const getLoginError = (error: AxiosError | unknown): JSX.Element => {
  error = error as AxiosError
  let loginError = <></>

  if (error instanceof AxiosError) {
    if (!error?.response?.status || error.response.status === 500) {
      loginError = <p>{Errors.somethingUnexpectedError}</p>
      LogError(error, 'Login failed')
    } else if (error.response.status === 401 || error.response.status === 403) {
      loginError = <p>{Errors.invalidLoginAttempt}</p>
    } else if (error.response.status === 422) {
      // expired password
      const data: ProblemDetails = error?.response?.data

      if (data?.detail) {
        loginError = (
          <p>
            Your password has expired,{' '}
            <Link to={data?.detail}>please change your password.</Link>
          </p>
        )
      } else {
        // I don't think this should be hit but better to have a backup than give an empty link
        loginError = (
          <p>Your password has expired, please change your password.</p>
        )
      }
    } else if (error.response.status === 423) {
      // locked account
      loginError = (
        <p>
          Your account has been locked due to failed attempts, please use{' '}
          <Link to='/Account/ForgotPassword'>'Forgot My Password'</Link> to
          unlock.
        </p>
      )
    } else if (error.response.status === 424) {
      // inactive account
      loginError = <p>{Errors.inactiveUser}</p>
    }
  }

  //not axios or not a status code we're handling
  if (!loginError) {
    loginError = (
      <p>
        Error logging in, please try again or contact support if the issue
        persists.
      </p>
    )
    LogError(error, 'Login failed')
  }

  return loginError
}

/** logs the user out */
export const logoutUser = async (ldClient: LDClient): Promise<boolean> => {
  let isLoggedOut = false
  try {
    if (GlobalState.Auth.peek()) {
      //prevent this from being called multiple times in a row
      const { status } = await Logout(
        GlobalState.Auth.AccessToken.peek(),
        GlobalState.Auth.RegistrationToken.peek(),
        GlobalState.Auth.AuthToken.peek(),
      )

      if (status === 200) isLoggedOut = true
      cleanupState(ldClient) //fire and forget, no reason to wait on it and the interceptor will log any errors
    }
  } catch (e: unknown) {
    LogError(e as Error, 'Failed to logout user')
  }

  return Promise.resolve(isLoggedOut)
}

export const logoutUserAndRedirect = (
  searchParams: URLSearchParams,
  currentLocation: Location,
  ldClient: LDClient,
  navigate: NavigateFunction,
  error?: string,
) => {
  const loginUrl = getLoginPageUrl(searchParams, currentLocation)
  logoutUser(ldClient)
    .then((res) => {
      if (!res) cleanupState(ldClient)
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      navigate(loginUrl, { replace: true, state: { loginError: error } })
    })
}

// clears the user's login state
const cleanupState = (ldClient: LDClient) => {
  batch(() => {
    destroyStorage(GlobalState.UserInfo.userName.peek())
    GlobalState.delete()

    clearLdContext(ldClient).catch(() => {})
    clearPendo()
    ClearSentryUser()
  })
}

/** Gets login redirect url, if a return url doesn't already exist it adds one based on the current location */
export const getLoginPageUrl = (
  searchParams: URLSearchParams,
  location: Location,
) => {
  const redirectUrl =
    location.pathname && location.pathname !== '/'
      ? location.pathname + location.hash
      : ''
  // sso uses a returnurl param when coming from another site so better to keep it uniform
  if (redirectUrl) searchParams.set('returnurl', redirectUrl)

  return `/Account/Login?${searchParams.toString()}`
}

/** Removes login tokens and returns the search params in lowercase */
const cleanLoginQuery = (searchParams: URLSearchParams) => {
  const lowerSearchParams = getSearchParamsLowerCase(searchParams)
  lowerSearchParams.delete('token')
  lowerSearchParams.delete('eat')
  return lowerSearchParams
}

/**
 * Gets a query string for after login, removes sso/eat tokens
 */
export const getPostLoginQueryString = (
  searchParams: URLSearchParams,
): string => {
  const lowerSearchParams = cleanLoginQuery(searchParams)
  lowerSearchParams.delete('returnurl')

  const query = lowerSearchParams.toString()
  if (query) return '?' + query

  return ''
}
