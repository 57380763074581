import { LoadingOutlined } from '@ant-design/icons'
import { observer } from '@legendapp/state/react'
import { FC, useState } from 'react'

import { getEligibilityStatusInfo } from 'ui'

import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import { EligibilityPatients } from '../../../../../api/eligibility/eligibility-client/api'
import { useEligibilityContext } from '../../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../../shared/context/PatientEligibilityDetailContext'
import {
  handleEligibilityResponse,
  handleVerify,
} from '../utilities/eligibilityRowActions'

const ActionColumnCell = observer(
  ({ tableItem }: { tableItem: EligibilityPatients }) => {
    const flags = LDFlags$.get()
    const practiceDetails = GlobalState.PracticeInfo.get()

    const {
      participatingCarriers,
      providers,
      setRequiresTableRefresh,
      setShowEligibilityResponseModal,
      setVerificationResponse,
    } = useEligibilityContext()
    const {
      handleGetPatient,
      patient,
      setPatient,
      setShowingPatientResponseModal,
      formatPatientResponse,
      setPatientEligibilityResponse,
    } = usePatientEligibilityDetailContext()

    const info = getEligibilityStatusInfo(
      tableItem.Status,
      GlobalState.EligibilityStatusMapping.peek(),
    )

    return (
      <>
        {info.status === 'Unverified' ? (
          <GenericActionCell
            label='Verify'
            clickFunction={() =>
              handleVerify(
                GlobalState.Auth.peek(),
                flags.operaVynesyncAutomatedeligibility,
                patient,
                tableItem.PatientId,
                participatingCarriers,
                practiceDetails,
                providers,
                setPatient,
                setRequiresTableRefresh,
                setShowEligibilityResponseModal,
                setVerificationResponse,
              )
            }
          />
        ) : info.status !== 'Pending Response' &&
          (tableItem.ResponseDate || tableItem.PatientFormResponse) ? (
          <GenericActionCell
            label='View Response'
            clickFunction={() =>
              handleEligibilityResponse(
                GlobalState.Auth.peek(),
                formatPatientResponse,
                patient,
                tableItem.PatientId,
                participatingCarriers,
                practiceDetails,
                providers,
                setPatient,
                setShowEligibilityResponseModal,
                setShowingPatientResponseModal,
                setVerificationResponse,
                tableItem,
                setPatientEligibilityResponse,
              )
            }
          />
        ) : info.status !== 'Pending Response' ? (
          <GenericActionCell
            label='View Patient Info'
            clickFunction={() =>
              handleGetPatient({
                PatientId: tableItem?.PatientId,
                PatientFirstName: tableItem?.PatientFirstName,
                PatientLastName: tableItem?.PatientLastName,
                Status: tableItem?.Status,
              })
            }
          />
        ) : (
          <></>
        )}
      </>
    )
  },
)
interface GenericActionCellProps {
  clickFunction: () => void
  label: string
}

const GenericActionCell: FC<GenericActionCellProps> = ({
  clickFunction,
  label,
}): JSX.Element => {
  const [isPending, setisPending] = useState<boolean>(false)

  const handleClick = async () => {
    setisPending(true)

    await clickFunction()

    setisPending(false)
  }

  return (
    <section onClick={handleClick}>
      {label && <span className='text-link'>{label}</span>}
      {isPending && (
        <LoadingOutlined
          className='ml-100'
          spin
          style={{ fontSize: '1em' }}
        />
      )}
    </section>
  )
}

export default ActionColumnCell
