import { LoadingOutlined } from '@ant-design/icons'
import { Dispatch, ReactElement, SetStateAction, useState } from 'react'

import { EligibilityPatients } from '../../../../../api/eligibility/eligibility-client'
import { IAuthenticatedApiModel } from '../../../../../utilities/api'
import { showMessage } from '../../../../../utilities/general'
import { usePatientEligibilityDetailContext } from '../../../shared/context/PatientEligibilityDetailContext'
import { EligibilityResponseProps } from '../../../shared/EligibilityResponse'
import { getEligibilityResponse } from '../../../shared/utilities/getEligibilityResponse'

interface EligibilityActionCellProps<TableItem> {
  authentication: IAuthenticatedApiModel
  tableItem: TableItem
  icon?: ReactElement
  label?: string
  setShowEligibilityResponseModal: Dispatch<SetStateAction<boolean>>
  setVerificationResponse: Dispatch<SetStateAction<EligibilityResponseProps>>
}

const EligibilityActionCell = <TableItem,>({
  authentication,
  tableItem,
  icon = null,
  label = null,
  setShowEligibilityResponseModal,
  setVerificationResponse,
}: EligibilityActionCellProps<TableItem>) => {
  const [isPending, setisPending] = useState<boolean>(false)

  const { setPatientEligibilityResponse } = usePatientEligibilityDetailContext()

  const handleEligibilityResponse = async () => {
    setisPending(true)
    await getEligibilityResponse<EligibilityPatients>(authentication, tableItem)
      .then((data) => {
        if (data.patientResponse)
          setPatientEligibilityResponse(data.patientResponse)
        setVerificationResponse(data)
        setShowEligibilityResponseModal(true)
      })
      .catch((error) => showMessage(error.message, 'error'))
      .finally(() => setisPending(false))
  }

  return (
    <section onClick={handleEligibilityResponse}>
      {label && <span className='text-link'>{label}</span>}
      {icon && <span className='fs-100'>{icon}</span>}
      {isPending && (
        <LoadingOutlined
          className='ml-100'
          spin
          style={{ fontSize: '1em' }}
        />
      )}
    </section>
  )
}

export default EligibilityActionCell
