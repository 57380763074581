import billingStatementsImage from '../../../assets/in_app_billing_statements.png'
import campaignsImage from '../../../assets/in_app_campaigns.png'
import carrierListImage from '../../../assets/in_app_carrier_list.png'
import claimsImage from '../../../assets/in_app_claims.png'
import eligibilityImage from '../../../assets/in_app_eligibility.png'
import formsImage from '../../../assets/in_app_forms.png'
import liveWebchatImage from '../../../assets/in_app_live_webchat.png'
import onlineSchedulingImage from '../../../assets/in_app_online_scheduling.png'
import patientMessagingImage from '../../../assets/in_app_patient_messaging.png'
import remindersImage from '../../../assets/in_app_reminders.png'
import reviewsImage from '../../../assets/in_app_reviews.png'
import emailImage from '../../../assets/in_app_secure_email.png'
import teamChatImage from '../../../assets/in_app_team_chat.png'

export interface AdditionalServiceContentData {
  bullets?: string[]
  disclaimer?: string
  img: string
  paragraph?: string[]
  quote?: string[]
  title: JSX.Element
  url: string
}

interface AdditionalServiceContentTypes {
  attachments: AdditionalServiceContentData
  billingStatements: AdditionalServiceContentData
  carrierList: AdditionalServiceContentData
  claims: AdditionalServiceContentData
  campaigns: AdditionalServiceContentData
  eligibility: AdditionalServiceContentData
  email: AdditionalServiceContentData
  forms: AdditionalServiceContentData
  liveWebchat: AdditionalServiceContentData
  onlineScheduling: AdditionalServiceContentData
  patientMessaging: AdditionalServiceContentData
  reminders: AdditionalServiceContentData
  reviews: AdditionalServiceContentData
  teamChat: AdditionalServiceContentData
}

const formattedTM = (
  <span className='additional-service__title-trademark'>&reg;</span>
)

export const additionalServiceContentData: AdditionalServiceContentTypes = {
  attachments: {
    bullets: [
      `Easily Add, edit and label images`,
      `Ability to include NEA# to the claim in your practice software or on a payer portal`,
    ],
    img: claimsImage,
    paragraph: [
      `Effortlessly send patient attachments - without the need to send the claim!`,
    ],
    title: (
      <>
        Send attachments electronically - without having to send in the
        associated claim via Vyne Trellis
        {formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=attachments`,
  },
  billingStatements: {
    bullets: [
      `Accelerate your patient statement workflow`,
      `Easily view the processing status of your statements`,
      `Know where your statement was mailed even if your patient has moved`,
      `Simple, competitive rates`,
    ],
    img: billingStatementsImage,
    paragraph: [
      'Send printed billing statements to the patients of your choice – with just a few clicks.',
    ],
    title: (
      <>
        Eliminate printing, folding, stuffing, sealing, stamping, and mailing.
      </>
    ),
    url: 'https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=billing-statements',
  },
  carrierList: {
    img: carrierListImage,
    paragraph: [
      `We’ve improved your end-to-end revenue cycle management platform to better manage carrier access with a new tool enabling you to view and search carriers within the Vyne DentalⓇ network. Access the Carrier List to view carrier information (including the services they participate in, e-claim IDs, address, and contact information) as well as attachment procedure code requirements.`,
    ],
    title: (
      <>
        Carrier List via Vyne Trellis
        {formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=carrierlist`,
  },
  claims: {
    img: claimsImage,
    paragraph: [
      `Process, track, and manage all your claims electronically for one low monthly fee and attach unlimited images and documents to increase your approval rate and accelerate your reimbursements. Save time and get paid faster.`,
    ],
    title: (
      <>
        Unlimited Electronic Claims and Attachments in One, Seamless Flow via
        Vyne Trellis
        {formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=claims`,
  },
  eligibility: {
    img: eligibilityImage,
    paragraph: [
      `Confirm your patients’ insurance plan eligibility and benefit breakdown instantly and automatically, in real time or in batches. Spend less time on the phone with payers, and more time with the patients you serve.`,
    ],
    title: (
      <>
        Batch and Real-Time Eligibility Verification and Benefit Breakdown via
        Vyne Trellis
        {formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=eligibility`,
  },
  email: {
    bullets: [
      `Flexible options for message auditing, notifications, expiration, and email delivery retraction`,
      `Use your existing email address`,
      `Requires minimal training, easy to use`,
      `Recipients can view, download, and reply to emails sent for free`,
    ],
    img: emailImage,
    paragraph: [
      `Take control of your communications while protecting your patients and practice.`,
    ],
    title: (
      <>
        Send sensitive patient health information with our secure email feature
        by Vyne Trellis
        {formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=secure-email`,
  },
  forms: {
    disclaimer: `Testimonials are based on an individual's product experience and may vary by user. Write back capabilities vary based on the practice management system.`,
    img: formsImage,
    paragraph: [
      `Effortlessly collect intake forms that write back to your practice management system and reduce manual data entry. Write back capabilities vary based on practice management system.`,
    ],
    quote: [
      `"Vyne is a one-stop shop for claims processing, forms, and patient communication. It is more affordable than going with your software company. The representatives are always quick to respond with any question you may have."`,
      `-Hale Family Dentistry`,
    ],
    title: (
      <>
        Elevate your patient experience by digitizing and automating the intake
        process with integrated forms by Vyne Trellis{formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=forms`,
  },
  liveWebchat: {
    img: liveWebchatImage,
    paragraph: [
      'Connect with patients 24/7 and capitalize on every opportunity by equipping your website with our Live Webchat feature. Live Webchat can provide answers to patients and allow them to schedule appointments on their time and not just during your office hours.',
    ],
    title: (
      <p>
        <span>
          With real people trained to answer questions specific to your
          practice, you can qualify patients and collect important information
          needed to book an appointment. All with Vyne Trellis
        </span>
        <sup className='fs-150'>&reg;</sup>
      </p>
    ),
    url: 'https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=live-webchat',
  },
  onlineScheduling: {
    bullets: [
      `Integrate directly to your practice management system's appointment book`,
      `Customize the slots you "open" for self-appointment`,
      `Capture new patient bookings without the phone ringing`,
      `Be as rigid or as fluid as you want with appointment durations,provider availability and procedure types allowed`,
    ],
    paragraph: [
      `Give patients the power to view available openings in real-time and schedule an appointment in a time slot that's both optimal for them and fully controlled by you.`,
    ],
    img: onlineSchedulingImage,
    title: (
      <>
        Secure new patients 24/7 with integrated, fully customizable online
        scheduling by Vyne Trellis{formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=online-scheduling`,
  },
  patientMessaging: {
    bullets: [
      `Save time and increase efficiency with "collision detection" that prevents staff from sending conflicting messages to patients`,
      `Seamless integration with your practice management system means no manual data entry, and an up-to-date patient list`,
      `Enhance patient satisfaction and experience with convenient, timely communication that meets their needs`,
      `Streamline operations and reduce phone call volume with a simple, intuitive messaging platform`,
    ],
    img: patientMessagingImage,
    title: (
      <>
        Introducing Vyne Trellis{formattedTM} Patient Messaging - your next
        generation 2-way text communication platform for patients
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=patient-messaging`,
  },
  reminders: {
    bullets: [
      `Suggest alternate times or reschedule on the fly`,
      `Detect them and deliver a welcome letter with new patient forms`,
      `Gather current patient insurance information, including pictures of insurance cards`,
    ],
    disclaimer: `Third-party trademarks are the property of their owners.`,
    img: remindersImage,
    paragraph: [
      `Keep your calendar full with personalized patient reminders and confirmations automatically sent by text and email.`,
    ],
    title: (
      <>
        Increase Patient Satisfaction and Reduce No-Shows with Automated Patient
        Messaging & Email Reminders by Vyne Trellis{formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=reminders`,
  },
  reviews: {
    disclaimer: `*Availability and use of Vyne Trellis' mobile payments feature is subject to credit approval of dental practice by Global Payments Integrated. Dental practice must complete merchant application, account registration and agree to terms directly with Global Payments Integrated. Global Payments Integrated is a service mark of Global Payments Inc.® All rights reserved.`,
    img: reviewsImage,
    paragraph: [
      `An easy solution that automatically guides your patients to leave reviews on your social media pages and flags issues for your team to resolve privately.`,
    ],
    title: (
      <>
        Boost your brand and grow your online reputation with our intelligent
        patient reviews system by Vyne Trellis{formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=reviews`,
  },
  teamChat: {
    bullets: [
      `Share treatment estimates, benefits detail, pre-authorizations, eligibile financing, and more, in real time`,
      `Create better case acceptance and patient experiences with the necessary data you need at your fingertips`,
      `Boost productivity and empower team members by providing easy access to the information they need, regardless of their location within the office, all in an encrypted environment`,
    ],
    img: teamChatImage,
    paragraph: [
      `Connect your front and back office, on virtually any device within your network.`,
    ],
    title: (
      <>
        Launch seamless communication in your practice by activating Team Chat
        by Vyne Trellis{formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=teamchat`,
  },
  campaigns: {
    img: campaignsImage,
    paragraph: [
      `Streamline appointment scheduling by automating outreach while utilizing patient data such as existing treatment plans and other custom filters.`,
    ],
    title: (
      <>
        Segment your patient population based on procedures needed, insurance
        coverage, last treatment date, and more. Send customized texts or emails
        to patients and keep your schedule full. All with Vyne Trellis
        {formattedTM}
      </>
    ),
    url: `https://vynedental.com/vyne-trellis-activate/?utm_mediumtrellisinapp&utm_content=campaigns`,
  },
}
