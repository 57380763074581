import { Modal } from 'antd'
import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { TextFinder } from 'ui'

import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../shared/context/PatientEligibilityDetailContext'
import EligibilityResponse from '../../shared/EligibilityResponse'

import CloseIcon from 'trellis:assets/close-icon.svg?react'
import DownloadIcon from 'trellis:assets/download.svg?react'
import PrinterIcon from 'trellis:assets/printer-outlined.svg?react'
import useGeneratePDF from 'trellis:utilities/hooks/useGeneratePdf'
import './EligibilityResponseModal.scss'

interface EligibilityResponseModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const EligibilityResponseModal: FC<EligibilityResponseModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { setVerificationResponse, verificationResponse } =
    useEligibilityContext()

  const { setPatientEligibilityResponse } = usePatientEligibilityDetailContext()

  const responseElementRef = useRef<HTMLElement | null>(null)

  const { generatePDF } = useGeneratePDF()

  const handlePrintResponse = useReactToPrint({
    content: () => responseElementRef.current,
  })

  const handleModalClose = () => {
    setVerificationResponse(null)
    setPatientEligibilityResponse(null)
    setIsOpen(false)
  }

  const handleGeneratePDF = () => {
    const container = document.getElementsByClassName('eligibility-response__content--original')

    const contentHeight = container[0].scrollHeight || 1000
    generatePDF({ htmlHeight: contentHeight, htmlContent: verificationResponse?.verificationResponse || '', fileName: 'eligibility-response' })
  }

  const modalHeader = (
    <section className='eligibility-response-modal__header'>
      <h4>Patient Verification</h4>
      <TextFinder elementRef={responseElementRef} />
      <div
        aria-label='export verification'
        className='eligibility-response-modal__header-export-pdf'
        onClick={handleGeneratePDF}
      >
        <DownloadIcon />
      </div>
      <div
        aria-label='print verification'
        className='eligibility-response-modal__header-print'
        onClick={handlePrintResponse}
      >
        <PrinterIcon />
      </div>
      <div
        aria-label='close verification'
        className='eligibility-response-modal__header-close'
        onClick={handleModalClose}
      >
        <CloseIcon />
      </div>
    </section>
  )

  return (
    <Modal
      className='eligibility-response-modal'
      closable={false}
      destroyOnClose
      footer={null}
      onCancel={handleModalClose}
      open={isOpen}
      styles={{
        body: {
          maxHeight: window.innerHeight - 100,
        }
      }}
      title={modalHeader}
      width='90vw'
    >
      <EligibilityResponse
        ref={responseElementRef}
        response={verificationResponse}
      />
    </Modal>
  )
}

export default EligibilityResponseModal
