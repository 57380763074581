import { Modal } from 'antd'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'

import AttachmentImageDisplay from 'trellis:components/Attachments/AttachmentImage/attachmentImageDisplay'
import EditImageControls from 'trellis:components/Attachments/AttachmentImage/editImageControls'
import { ClaimDetailsModelExtended } from 'trellis:components/claims/context/claimDetailContext'

import { ImageType } from '../../../api/attachment/attachment-client/api'
import { formatDateFromString, showMessage } from '../../../utilities/general'
import {
  getUrlForAttachment,
  IAttachmentImage,
  IImageOperation,
  ImageOrientationType,
} from '../attachment.interface'
import styles from './attachmentImageDetails.module.scss'
import ImageDateTaken from './components/imageDateTaken'
import ImageTypeDropDown from './components/imageTypeDropdown/imageTypeDropdown'
import SaveImage from './components/saveImage'
import XRayOrientation from './components/xRayOrientation'

interface AttachmentImageDetails {
  attachmentImage: IAttachmentImage
  storeAttachmentImageLocal: (
    attachmentImage: IAttachmentImage,
    openDetailView: boolean,
  ) => void
  attachmentImageTypes: MutableRefObject<ImageType[]>
  overMaxSize: boolean
  showImageDetailsView: boolean
  claim: ClaimDetailsModelExtended
}

const AttachmentImageDetails: FC<AttachmentImageDetails> = ({
  attachmentImage,
  storeAttachmentImageLocal,
  attachmentImageTypes,
  overMaxSize,
  showImageDetailsView,
  claim,
}) => {
  const isMounted = useRef(true)
  const keyId = useRef<number>(0)
  const croppedImageRef = useRef<HTMLImageElement>()

  const currentCanvasBlobRef = useRef<Blob>()

  const [imageType, setImageType] = useState<ImageType>(
    attachmentImage?.imageTypeId == null
      ? null
      : {
          imageTypeId: attachmentImage?.imageTypeId,
          imageTypeName: attachmentImageTypes.current.filter(
            (e) => e.imageTypeId == attachmentImage.imageTypeId,
          )[0]?.imageTypeName,
          isXray: attachmentImageTypes.current.filter(
            (e) => e.imageTypeId == attachmentImage.imageTypeId,
          )[0]?.isXray,
        },
  )

  let imageOrientationType = ImageOrientationType.LeftIsRight
  if (localStorage.getItem('localStorageImageOrientation') == 'LEFT') {
    imageOrientationType = ImageOrientationType.LeftIsLeft
  }
  const [orientation, setOrientation] = useState<ImageOrientationType>(
    attachmentImage?.imageOrientationType ?? imageOrientationType,
  )
  const [imageDate, setImageDate] = useState<Date>(
    attachmentImage.imageDateTaken,
  )
  const [isXrayType, setIsXrayType] = useState<boolean>(
    attachmentImage &&
      attachmentImageTypes.current.filter(
        (e) => e.imageTypeId == attachmentImage.imageTypeId,
      )[0]?.isXray,
  )
  const [operations, setOperations] = useState<IImageOperation>()
  const [originalImg, setOriginalImg] = useState<HTMLImageElement>()
  const [imgForCanvas, setImgForCanvas] = useState<HTMLImageElement>()
  const defaultImageDropdownText = 'Select Type'
  const [activeImageTypeOption, setActiveImageTypeOption] = useState<string>(
    attachmentImage.imageTypeName ?? defaultImageDropdownText,
  )

  const getPatientName = (name: string) => {
    if (!name) return ''
    return `${name.split(',')[1]?.trim()} ${name.split(',')[0].trim()}`
  }

  useEffect(() => {
    const setInitalSetup = async () => {
      if (isMounted.current) {
        //this is needed in case the image type being loaded is xray, we need to show orientation
        if (imageType && imageType.imageTypeId > 0) {
          await setIsXrayType(imageType.isXray)
          if (!imageType.isXray) {
            setOrientation(ImageOrientationType.None)
          }
        }

        ;() => {
          isMounted.current = false
        }
      }

      if (!originalImg) {
        const origImg = new Image()
        origImg.src = getUrlForAttachment(attachmentImage)
        origImg.onload = () => {
          croppedImageRef.current = origImg
          setOriginalImg(origImg)
          setImgForCanvas(origImg)
        }
      }

      keyId.current = (await (keyId.current === 0)) ? 1 : 0
    }

    setInitalSetup()
  }, [])

  function saveData() {
    if (
      activeImageTypeOption === defaultImageDropdownText ||
      activeImageTypeOption === ''
    ) {
      showMessage('Image type is required', 'error')
      return
    }

    if (isXrayType && !imageDate) {
      showMessage('Date taken is required', 'error')
      return
    }

    if (isXrayType && orientation == null) {
      showMessage('Orientation is required', 'error')
      return
    }

    // Update attachmentImage with changes
    attachmentImage.imageDateTaken = imageDate
    attachmentImage.imageTypeName = imageType && imageType.imageTypeName
    attachmentImage.imageTypeId = imageType && imageType.imageTypeId
    attachmentImage.imageOrientationType = orientation
    if (currentCanvasBlobRef.current) {
      attachmentImage.imageData = new File(
        [currentCanvasBlobRef.current],
        attachmentImage.imageData.name,
        { type: attachmentImage.imageData.type },
      )
    }

    storeAttachmentImageLocal(attachmentImage, false)
  }

  function updateCurrentCanvasImageRef(newCanvasImage: Blob) {
    currentCanvasBlobRef.current = newCanvasImage
  }

  function updateCroppedImg(newCroppedImg: HTMLImageElement) {
    croppedImageRef.current = newCroppedImg
  }

  return (
    <Modal
      closable={false}
      destroyOnClose
      open={showImageDetailsView}
      footer={null}
      mask={false}
      style={{ top: 64, maxWidth: '100%', paddingBottom: 0, maxHeight: '100%' }}
      height={'100%'}
      width={'100%'}
    >
      <div className={styles['attachment-image-detail']}>
        <div className={styles['image-detail__sidebar']}>
          <div>
            <p className='fs-150'>{getPatientName(claim?.PatientName)}</p>
            <p className='fs-0875 mb-250'>
              {`Date of Service: ${
                claim?.LineItems[0]?.SERVICE_DATE_String &&
                formatDateFromString(
                  claim.LineItems[0].SERVICE_DATE_String,
                  'MM/dd/yyy',
                )
              }`}
            </p>
          </div>
          <ImageTypeDropDown
            setImageType={setImageType}
            setIsXrayType={setIsXrayType}
            setOrientation={setOrientation}
            activeImageTypeOption={activeImageTypeOption}
            setActiveImageTypeOption={setActiveImageTypeOption}
            defaultImageDropdownText={defaultImageDropdownText}
            orientation={orientation}
          />
          {isXrayType && (
            <XRayOrientation
              setOrientation={setOrientation}
              orientation={orientation}
            />
          )}
          <ImageDateTaken
            setImageDate={setImageDate}
            imageDate={imageDate}
          />
        </div>
        <div className={styles['image-detail__header']}>
          <div className={styles['image-detail__header--controls']}>
            <EditImageControls
              attachmentImage={attachmentImage.imageData}
              keyId={keyId}
              operations={operations}
              setOperations={setOperations}
              setImgForCanvas={setImgForCanvas}
              croppedImageRef={croppedImageRef}
            />
            <SaveImage
              overMaxSize={overMaxSize}
              storeAttachmentImageLocal={storeAttachmentImageLocal}
              saveData={saveData}
            />
          </div>
        </div>
        <div className={styles['image-detail__body']}>
          <AttachmentImageDisplay
            imageFile={attachmentImage.imageData}
            key={keyId.current.toString()}
            performOperations={operations}
            imgForCanvas={imgForCanvas}
            setImgForCanvas={setImgForCanvas}
            cropImgCallback={updateCroppedImg}
            currentCanvasToBlobCallback={updateCurrentCanvasImageRef}
            showPreviewText={false}
          >
            {null}
          </AttachmentImageDisplay>
        </div>
      </div>
    </Modal>
  )
}

export default AttachmentImageDetails
