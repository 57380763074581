import { Observable } from '@legendapp/state'

import { LogError } from 'utils'

import {
  ClaimActivityLogFieldUpdate,
  ClaimStatusCodes,
} from 'trellis:api/claim/claim-client'
import { GetClaimStatusCodes } from 'trellis:api/claim/claimApi'
import { LDFlags$ } from 'trellis:state/globalState'

import * as claimStatusData from '../../../constants/claimStatusDescriptionData'
import { MappedClaimActivityLogRecord } from '../components/ClaimActivityLog/ClaimActivityLog'
import { claimFormFieldsMap } from './claim-form-fields-mapping'

export const matchClaimStatus = (claimsItem: MappedClaimActivityLogRecord) => {
  const match = claimStatusData.getStatusOverride(claimsItem.Status)

  if (match) {
    claimsItem.Status = match.Status
    claimsItem.StatusTooltip = match.StatusTooltip
  }

  return claimsItem
}

const matchClaimStatusTooltip = (tooltipID: number): string | void => {
  const match = claimStatusData.descriptionTooltips.find(
    (item: claimStatusData.DescriptionTooltipData) =>
      item.descriptionTooltipID === tooltipID,
  )

  if (match) return match.descriptionTooltip

  return
}

const matchClaimStatusDescriptionOverride = (
  descriptionOverrideID: number,
): string | void => {
  const match = claimStatusData.descriptionOverrides.find(
    (item: claimStatusData.DescriptionOverrideData) =>
      item.descriptionOverrideID === descriptionOverrideID,
  )

  if (match) return match.descriptionOverride

  return
}

export const matchClaimStatusDescription = (claimsItem: any) => {
  const match = claimStatusData.descriptions.find(
    (item: claimStatusData.DescriptionData) =>
      item.description.toLocaleLowerCase() ===
      claimsItem.StatusDescription?.toLocaleLowerCase(),
  )

  if (match) {
    claimsItem.StatusDescription = match.descriptionOverrideID
      ? matchClaimStatusDescriptionOverride(match.descriptionOverrideID)
      : claimsItem.StatusDescription
    claimsItem.StatusDescriptionTooltip = match.descriptionTooltipID
      ? matchClaimStatusTooltip(match.descriptionTooltipID)
      : claimsItem.StatusDescriptionTooltip
  }

  return claimsItem
}

export const mapFieldUpdateNames = (field: ClaimActivityLogFieldUpdate) => {
  claimFormFieldsMap.forEach((item) => {
    if (item.fieldName === field.FieldName) {
      field.FieldName = item.fieldLabel
    }
  })
}
